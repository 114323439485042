import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {
    TextField,
    Button,
    Grid,
    Box,
    Typography,
    Modal,
    Paper,
    IconButton,
    Stack,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel
} from "@mui/material";
import {
    Close as CloseIcon,
} from "@mui/icons-material";
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {clearMessage, selectMessage} from '../../reducers/utils/messageReducer';
import {useForm, SubmitHandler, Controller, DefaultValues} from "react-hook-form";
import {sendMessageAsync} from "../../reducers/utils/contactReducer";
import {User} from "../../types/database";


const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: "absolute",
    top: "45%",
    left: "50%",
    width: '100%',
    transform: "translate(-50%, -50%)",
    bgcolor: "transparent",
    border: "0px solid #000",
    boxShadow: 0,
    borderRadius: "0%",
    p: 0,
};

interface ModalProps {
    visible: boolean,
    setModalVisible: (active: boolean) => void,
}

export const defaultValues: DefaultValues<any> = {
    name: "",
    email: "",
    message: "",
};

export default function ContactForm({visible, setModalVisible}: ModalProps) {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(clearMessage());
        reset(defaultValues)
    }, [dispatch]);


    const {register, setValue, handleSubmit, watch, reset, control, formState: {errors}} = useForm<any>({
        defaultValues
    });

    useEffect(() => {
        if (defaultValues) {

            setValue("name", '', {shouldDirty: true});
            setValue("email", '', {shouldDirty: true});
            setValue("message", '', {shouldDirty: true});

        }
    }, [defaultValues, setValue]);


    const onSubmit: SubmitHandler<any> = data => {
        setModalVisible(false);
        dispatch(sendMessageAsync(data))
    }

    const message = useAppSelector(selectMessage);

    function handleModalClose() {
        setModalVisible(false);
    }

    return (
        <Modal
            open={visible}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={modalStyle}
            >
                <Paper
                    sx={{
                        p: 1,
                        mt: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                    }}
                >
                    <Grid
                        container
                        spacing={1}
                        mt={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item={true} xs>
                        </Grid>
                        <Grid item={true} xs="auto">
                            <Typography component="h1" variant="h5">
                                Contact us
                            </Typography>
                        </Grid>
                        <Grid item={true} xs>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={6}
                            >
                                <IconButton onClick={handleModalClose}>
                                    <CloseIcon sx={{fontSize: "30px"}}/>
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Controller
                            control={control}
                            name={"name"}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register("name")}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoFocus
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={"email"}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register("email")}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    autoComplete="email"
                                    autoFocus
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"message"}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register("message")}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="message"
                                    label="Message"
                                    name="message"
                                    autoFocus
                                    multiline
                                    rows={3}
                                />
                            )}
                        />


                        <Stack spacing={3} mt={3} direction="row" justifyContent={"right"}>
                            <Button
                                variant="outlined"
                                size="medium"
                                onClick={() => {
                                    reset(defaultValues);
                                }}
                            >
                                clear
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                size="medium"
                                // fullWidth
                            >
                                send message
                            </Button>
                        </Stack>
                    </form>
                    <Box
                        sx={{
                            p: 2,
                            mt: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: 'auto',
                        }}
                    >

                        {message && (
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                sx={{mt: 2}}
                            >
                                {message}
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}