import {buffers, eventChannel, END} from 'redux-saga';

export const uploadApi = (endpoint: string, file: File) => {
    return eventChannel(emitter => {
            const formData = new FormData();
            formData.append('xml_file', file);
            const xhr = new XMLHttpRequest();
            const onProgress = (e: ProgressEvent) => {
                if (e.lengthComputable) {
                    const progress = Math.floor((e.loaded / e.total) *100);
                    emitter({progress});
                }
            };
            const onFailure = (error: any) => {
                emitter({success: false, error: error});
                emitter(END);
            };
            const onCanceled = (e: ProgressEvent) => {
                emitter({success: false, error: new Error('Przerwano przesyłanie.')});
                emitter(END);
            };
            xhr.upload.addEventListener("progress", onProgress);
            xhr.upload.addEventListener("error", onFailure);
            xhr.upload.addEventListener("abort", onCanceled);
            xhr.onreadystatechange = () => {
                const {readyState, status} = xhr;
                if (readyState === 4) {
                    if (status === 200) {
                        emitter({success: true});
                        emitter(END);
                    } else {
                        console.log(xhr.response)
                        let response;
                        if (JSON.parse(xhr.response)) {
                            response = JSON.parse(xhr.response);
                        } else {
                            response = "Błąd przetwarzania."
                        }
                        onFailure({
                         status: status,
                         error: response,
                        });
                    }
                }
            };
            xhr.open("POST", endpoint, true);
            // xhr.setRequestHeader("Authorization", `Client-ID ${IMGUR_CLIENT_ID}`);
            xhr.send(formData);
            return () => {
                xhr.upload.removeEventListener("progress", onProgress);
                xhr.upload.removeEventListener("error", onFailure);
                xhr.upload.removeEventListener("abort", onCanceled);
                xhr.onreadystatechange = null;
                xhr.abort();
            };
        },
        buffers.sliding<any>(2)
    );
}
