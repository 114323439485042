interface QueryFromProps {
    props: any;
}

const getQueryFromPropsItems = ({props}: QueryFromProps) => {
    const mappedProps: {[index: string]: string} = {};
    Object.keys({...props}).forEach((key) => {
        mappedProps[`${key}_like`] = props[key];
    });
    return `?${new URLSearchParams(mappedProps).toString()}`;
};
export default getQueryFromPropsItems;
