import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {login, logout, g2faAuth, g2faGetQRCode, g2faRegister} from "./signInApi";
import {setMessage} from "../utils/messageReducer";
import {RootState} from "../../app/store";
import {Cookies} from 'react-cookie'

const cookies = new Cookies();
// const userData = JSON.parse(sessionStorage.getItem("e-inkas-USER")!);
const userData = cookies.get("e-inkas-USER");

const initialState = userData
    ? {
        isLoggedIn: true,
        userData,
        status: 'idle',
    }
    : {
        isLoggedIn: false,
        userData: null,
        status: 'idle',
    };

export const loginAsync = createAsyncThunk(
    "user/login",
    async ({username, password}: { username: string, password: string }, thunkAPI) => {
        try {
            const data = await login({username: username, password: password});
            // cookies.set('e-inkas-USER', data.user, {
            //         // "maxAge": 20 ,  //sekundy (1h=3600s)
            //         "maxAge": 10 * 3600,  //sekundy (1h=3600s)
            //     }
            // )
            // sessionStorage.setItem("e-inkas-USER", JSON.stringify(data.user));
            return {userData: data};
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue('');
        }
    }
);

export const logoutAsync = createAsyncThunk(
    "user/logout",
    async (_, thunkAPI) => {
        try {
            // console.log('logout');
            // const response = await g2faGetQRCode();
            cookies.remove('e-inkas-USER');

            return {};
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue('');
        }
    }
);

export const authAsync = createAsyncThunk(
    "user/auth",
    async ({code, userData}: { code: string, userData: any }, thunkAPI) => {
        try {
            const response = await g2faAuth({code: code});
            cookies.set('e-inkas-USER', userData, {
                    // "maxAge": 10 ,  //sekundy (1h=3600s)
                    "maxAge": 10 * 3600,  //sekundy (1h=3600s)
                }
            )
            // console.log(response)
            return {response};
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue('');
        }
    }
);

export const registerAsync = createAsyncThunk(
    "user/register",
    async ({code, userData}: { code: string, userData: any }, thunkAPI) => {
        try {
            const response = await g2faRegister({code: code});
            cookies.set('e-inkas-USER', userData, {
                    // "maxAge": 20 ,  //sekundy (1h=3600s)
                    "maxAge": 10 * 3600,  //sekundy (1h=3600s)
                }
            )
            return {response};
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue('');
        }
    }
);

export const getQRCodeAsync = createAsyncThunk(
    "user/qrCode",
    async (_, thunkAPI) => {
        try {
            const response = await g2faGetQRCode();
            return {qrCode: response.qrCode};
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue('');
        }
    }
);

export const signInReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedIn: (state)=>{
            state.isLoggedIn = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = 'authorizing';
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                // state.isLoggedIn = true;
                // console.log(action.payload.userData.user);
                // state.isRegistered = action.payload.userData.user.g2fa;
                state.userData = action.payload.userData.user;
            })
            .addCase(loginAsync.rejected, (state) => {
                state.status = 'idle';
                state.isLoggedIn = false;
                state.userData = null;
            })
            .addCase(logoutAsync.pending, (state) => {
                state.status = 'logout pending';
            })
            .addCase(logoutAsync.fulfilled, (state) => {
                state.status = 'idle';
                state.isLoggedIn = false;
                state.userData = null;
            })
            .addCase(logoutAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(authAsync.pending, (state) => {
                state.status = 'authorizing';
            })
            .addCase(authAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.isLoggedIn = true;
            })
            .addCase(authAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(registerAsync.pending, (state) => {
                state.status = 'registering';
            })
            .addCase(registerAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.isLoggedIn = true;
            })
            .addCase(registerAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(getQRCodeAsync.pending, (state) => {
                state.status = 'generating';
            })
            .addCase(getQRCodeAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                // const {qrCode} = action.payload.qrCode;
            })
            .addCase(getQRCodeAsync.rejected, (state) => {
                state.status = 'idle';
            });
    }
});

export const { setLoggedIn } = signInReducer.actions;

export const selectUser = (state: RootState) => state.user.userData;
export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn;

export default signInReducer.reducer;

