import React, {useState} from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    Paper,
    Container,
    Grid,
    Typography,
    Button,
} from "@mui/material";

import Copyright from "../components/Copyrights";
import AppBarDrawer from "../components/AppBarDrawer";
import HomeLayout from "../components/HomeLayout";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {Fade, Slide, Bounce, JackInTheBox, Zoom} from "react-awesome-reveal";
import AppFooter from "../components/AppFooter";
import ContactForm from "../components/forms/ContactForm";
import {ToastContainer} from "react-toastify";
import {selectThemeSwitcher} from "../reducers/utils/settingsReducer";
import {useAppSelector} from "../app/hooks";

const backgroundImage = 'img/web7.jpg';

const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

function HomeContent() {


    const darkMode = useAppSelector(selectThemeSwitcher);
    const themeMode = darkMode ? "light" : "dark";

    const [isOpenContact, setIsOpenContact] = useState(false);

    const handleContactVisible = () => {
        setIsOpenContact(!isOpenContact);
    };


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBarDrawer title={''}/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                {/*<Toolbar/>*/}
                <HomeLayout
                    sxBackground={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundColor: '#7fc7d9',
                        backgroundPosition: 'center',
                    }}
                >
                    <img
                        style={{display: 'none'}}
                        src={backgroundImage}
                        alt="increase priority"
                    />
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h4"
                        fontFamily="Lynx"
                        sx={{
                            mt:2
                        }}
                    >
                        <Zoom>
                            palsoft
                        </Zoom>
                    </Typography>
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h3"
                        fontFamily="Raleway"
                    >
                        <Zoom>
                            Software Development
                        </Zoom>
                    </Typography>
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h5"
                        fontFamily="Raleway"
                        sx={{mb: 4, mt: {xs: 4, sm: 10}}}
                    >
                        Let's discuss your future project. Need a software development partner?

                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        component="a"
                        onClick={handleContactVisible}
                        sx={{minWidth: 200}}
                    >
                        contact us
                    </Button>
                    <Typography variant="body1" color="inherit" sx={{mt: 5}}>
                        Start in just weeks with our certified teams. We choose scalable and
                        flexible technologies.
                    </Typography>
                </HomeLayout>

                <Box
                    component="section"
                    sx={{display: 'flex', overflow: 'hidden'}}
                >
                    <Container sx={{mt: 15, mb: 30, display: 'flex', position: 'relative'}}>

                        <Box
                            component="img"
                            src="img/productCurvyLines.png"
                            alt="curvy lines"
                            sx={{pointerEvents: 'none', position: 'absolute', top: -180}}
                        />

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/flutter_app.png"
                                        alt="suitcase"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            Mobile App Development
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="h6">
                                            {
                                                'We create cross-platform mobile applications. '
                                            }
                                            {
                                                ' Boasting a shorter time to market, cross-platform apps run on multiple devices and operating systems.'
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/webapp_dev.png"
                                        alt="graph"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            Web App Development
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="h6">
                                            {
                                                'Grow your business with a beautifully designed web application. '
                                            }
                                            {
                                                'We can help you bring your web application idea to life. From crystallizing your idea to maintenance.'
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/web_dev.png"
                                        alt="clock"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            CMS Websites Development
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="h6">
                                            {
                                                'The website has become an essential and significant part of human life. Every business needs a website to showcase its skill, products, talents, and much more. '
                                            }
                                            {
                                                'We can help.'
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <AppFooter/>
            </Box>
            <ContactForm visible={isOpenContact} setModalVisible={handleContactVisible}/>
            <ToastContainer theme={`${themeMode}`}/>
        </Box>
    );
}

export default function Home() {
    return <HomeContent/>;
}
