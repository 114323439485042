import axios from 'axios';
import getQueryFromPropsItems from '../utils/getQueryFromPropsItems';
import getQueryFromPropsQuick from '../utils/getQueryFromPropsQuick';
import {User} from "../../types/database";

const API_URL = "/api/users/";

export const loadUsersAPI = async () => {
    return await axios.get(API_URL + "getAll?q=proxy'")
};

export const createUserAPI = async (user: User) => {
    return await axios.post(API_URL + "?q=proxy'", user);
};

export const deleteUserAPI = async (idUser: number) => {
    return await axios.delete(API_URL + idUser + "?q=proxy'");
};

export const updateUserAPI = async (user: User) => {
    return await axios.post(API_URL+"update?q=proxy'", user);
};

export const searchUsersAPI = async (queryParams: any) => {
    console.log('API search', queryParams)
    const quickQuery = getQueryFromPropsQuick({props: queryParams});
    console.log('API query', quickQuery)
    return await axios.get(API_URL + `search?q=proxy'`,  {
        params: {
            quick: quickQuery,
        },
    });
};

export const filterUsersByStatusAPI = async (status: any) => {
    // return await axios.get(`http://localhost:5000/users?status=${status}`);
};
export const sortUsersAPI = async (queryParams: any, sort: any) => {
    const query = getQueryFromPropsItems({props: queryParams});
    // return await axios.get(
    //     `http://localhost:5000/users${query ? query : ''}&_sort=${
    //         sort.name
    //     }&_order=${sort.type}`
    // );
};
