import * as types from './usersActionTypes';
import {User} from "../../types/database";

export const loadUsersStart = () => ({
  type: types.LOAD_USERS_START,
});

export const loadUsersSuccess = (users: User) => ({
  type: types.LOAD_USERS_SUCCESS,
  payload: users,
});

export const loadUsersError = (error: any) => ({
  type: types.LOAD_USERS_ERROR,
  payload: error,
});

// CREATE A NEW USER
export const createUserStart = (user: User) => ({
  type: types.CREATE_USER_START,
  payload: user,
});

export const createUserSuccess = (user: User) => ({
  type: types.CREATE_USER_SUCCESS,
  payload: user,
});

export const createUserError = (error: any) => ({
  type: types.CREATE_USER_ERROR,
  payload: error,
});

//UPDATE AN EXISTING USER
export const updateUserStart = (user: User) => ({
  type: types.UPDATE_USER_START,
  payload: user,
});

export const updateUserSuccess = (user: User) => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserError = (error: any) => ({
  type: types.UPDATE_USER_ERROR,
  payload: error,
});

// DELETE USER
export const deleteUserStart = (idUser: number) => ({
  type: types.DELETE_USER_START,
  payload: idUser,
});

export const deleteUserSuccess = (idUser: number) => ({
  type: types.DELETE_USER_SUCCESS,
  payload: idUser,
});

export const deleteUserError = (error: any) => ({
  type: types.DELETE_USER_ERROR,
  payload: error,
});

// SEARCH USERS
export const searchUsersStart = (query: any) => ({
  type: types.SEARCH_USERS_START,
  payload: query,
});

export const searchUsersSuccess = (users: User) => ({
  type: types.SEARCH_USERS_SUCCESS,
  payload: users,
});

export const searchUsersError = (error: any) => ({
  type: types.SEARCH_USERS_ERROR,
  payload: error,
});

//FILTER users
export const filterUsersStart = (userStatus: boolean) => ({
  type: types.FILTER_USERS_START,
  payload: userStatus,
});

export const filterUsersSuccess = (users: User) => ({
  type: types.FILTER_USERS_SUCCESS,
  payload: users,
});

export const filterUsersError = (error: any) => ({
  type: types.FILTER_USERS_ERROR,
  payload: error,
});

//SORT users
export const sortUsersStart = (sortValue: any) => ({
  type: types.SORT_USERS_START,
  payload: sortValue,
});

export const sortUsersSuccess = (users: User) => ({
  type: types.SORT_USERS_SUCCESS,
  payload: users,
});

export const sortUsersError = (error: any) => ({
  type: types.SORT_USERS_ERROR,
  payload: error,
});
