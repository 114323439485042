import * as types from './usersActionTypes';
import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call,
} from 'redux-saga/effects';

import {
    loadUsersSuccess,
    loadUsersError,
    createUserSuccess,
    createUserError,
    deleteUserSuccess,
    updateUserError,
    deleteUserError,
    searchUsersError,
    searchUsersSuccess,
    updateUserSuccess,
    filterUsersSuccess,
    filterUsersError,
    sortUsersSuccess,
    sortUsersError,
} from './usersActions';

import {
    createUserAPI,
    deleteUserAPI,
    loadUsersAPI,
    searchUsersAPI,
    sortUsersAPI,
    updateUserAPI,
} from './usersApi';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//PUT is used to dispatch action to store
//function PUT creates the dispatch effect
/*
 * onLoadUsersStartAsync is a Handler function responsible for the async logic (managing api)
 */
function* onLoadUsersStartAsync(): any {
    try {
        const response = yield call(loadUsersAPI);
        if (response.status) {
            if (response.status === 200) {
                yield put(loadUsersSuccess(response.data.users));
            }
        }
    } catch (error: any) {
        yield put(loadUsersError(error.response.status));
    }
}

function* onSearchUsersStartAsync({payload: query}: any): any {
    try {
        console.log('search query: ', query)
            const response = yield call(searchUsersAPI, query);
        console.log('response: ', response)
            if (response.status) {
                if (response.status === 200) {
                    yield put(searchUsersSuccess(response.data.users));
                }
            }
    } catch (error: any) {
        yield put(searchUsersError(error.message));
    }
}

function* onFilterUsersByStatusStartAsync({payload}: any): any {
    try {
        console.log('filter payload: ', payload)
        const response = yield call(searchUsersAPI, payload);
        if (response.status) {
            if (response.status === 200) {
                yield put(filterUsersSuccess(response.data));
            }
        }
    } catch (error: any) {
        yield put(filterUsersError(error.message));
    }
}

function* onSortUsersStartAsync({payload, sort}: any): any {
    try {
        const response = yield call(sortUsersAPI, payload, sort);
        if (response.status) {
            if (response.status === 200) {
                yield put(sortUsersSuccess(response.data));
            }
        }
    } catch (error: any) {
        yield put(sortUsersError(error.message));
    }
}

function* onCreateUserStartAsync({payload}: any): any {
    try {
        const response = yield call(createUserAPI, payload);
        if (response.status) {
            if (response.status === 201) {
                toast.success('User Added Successfully');
                yield put(createUserSuccess(response.data));
            }
        }
    } catch (error: any) {
        yield put(createUserError(error.response.status));
    }
}

function* onUpdateUserStartAsync({payload}: any): any {
    try {

        const response = yield call(updateUserAPI, payload);
        if (response.status) {
            if (response.status === 200) {
                toast.success('User Updated Successfully');
                yield put(updateUserSuccess(response.data));
            }
        }
    } catch (error: any) {
        yield put(updateUserError(error.message));
    }
}

function* onDeleteUserStartAsync(idUser: number): any {
    try {
        const response = yield call(deleteUserAPI, idUser);
        if (response.status) {
            if (response.status === 200) {
                toast.success('User Deleted Successfully');
                yield put(deleteUserSuccess(response.data));
            }
        }
    } catch (error: any) {
        console.log(error);
        yield put(deleteUserError(error.response.status));
    }
}

function* onLoadUsers(): any {
    yield takeEvery(types.LOAD_USERS_START, onLoadUsersStartAsync);
}

function* onCreateUser(): any {
    yield takeLatest(types.CREATE_USER_START, onCreateUserStartAsync);
}

// export function* onDeleteUser() {
//   yield takeLatest(types.DELETE_USER_START, onDeleteUserStartAsync);
// }
function* onDeleteUser(): any {
    while (true) {
        const {payload: idUser} = yield take(types.DELETE_USER_START);
        yield call(onDeleteUserStartAsync, idUser);
    }
}

export function* onUpdateUser(): any {
    yield takeLatest(types.UPDATE_USER_START, onUpdateUserStartAsync);
}

export function* onSearchUsers(): any {
    yield takeLatest(types.SEARCH_USERS_START, onSearchUsersStartAsync);
}

export function* onFilterUsersByStatus(): any {
    yield takeLatest(types.FILTER_USERS_START, onFilterUsersByStatusStartAsync);
}

export function* onSortUsers(): any {
    yield takeLatest(types.SORT_USERS_START, onSortUsersStartAsync);
}

//FORK allows you to run some tasks in a parallel fashion. Forking tasks will make them non-blocking so they will run smoothly in the background
const usersSagas = [
    fork(onLoadUsers),
    fork(onCreateUser),
    fork(onDeleteUser),
    fork(onUpdateUser),
    fork(onSearchUsers),
    fork(onFilterUsersByStatus),
    fork(onSortUsers),
];

//ROOT SAGA aggregates multiple Sagas to a single entry point for the sagaMiddleware to run
export default function* usersSaga(): any {
    yield all([...usersSagas]);
}
