import * as types from './uploadActionTypes';


interface StateType {
    loading: boolean,
    progress: number,
    message: string,
    error: string | null,
}

const initialState: StateType = {
    loading: false,
    progress: 0,
    message: "",
    error: null,
};

const uploadReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case types.UPLOAD_FILE_START:
            console.log('reducer file start')
            return {...state, loading: true, message: ""};
        case types.UPLOAD_FILE_SUCCESS:
            console.log('reducer file success')
            return {...state, loading: false, message: "Plik przesłano poprawnie"};
        case types.UPLOAD_FILE_ERROR:
            console.log('reducer file error', action.payload)
            return {...state, loading: false, message: "Błąd przetwarzania. Spróbuj ponownie.", error: action.payload};
        case types.UPLOAD_FILE_CANCEL:
            console.log('reducer file cancel')
            return {...state, loading: false, message: "Przerwano przesyłanie pliku", error: action.payload};
        case types.UPLOAD_FILE_PROGRESS:
            console.log('reducer file progress',  action.payload)
            return {...state, progress: action.payload};
        default:
            return state;
    }
};

export default uploadReducer;
