import { combineReducers } from '@reduxjs/toolkit';
import usersReducer from './users/usersReducer';
import uploadReducer from "./upload/uploadReducer";
import settingsReducer from "./utils/settingsReducer";
import signInReducer from "./signIn/signInReducer";
import messageReducer from "./utils/messageReducer";
import ppeReducer from "./ppePoints/ppeReducer";
import contactReducer from "./utils/contactReducer";

const _rootReducer = combineReducers({
  usersData: usersReducer,
  upload: uploadReducer,
  ppe: ppeReducer,
  themeSwitcher: settingsReducer,
  drawer: settingsReducer,
  density: settingsReducer,
  user: signInReducer,
  message: messageReducer,
  contact: contactReducer,
});

export default _rootReducer;
