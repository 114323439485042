import React from "react";
import {Typography} from "@mui/material";
import Link from "@mui/material/Link";


export default function Copyright(props: any) {
    return (
        <Typography position="absolute" bottom="10px" right="30px" variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://palsoft.dev/">
                PALSOFT
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}