import axios from "axios";

const API_URL = "/api/ppe/";

export function getLiczydla({idPaczki, cycles}: { idPaczki: number, cycles: string[] }) {
    return axios.get(API_URL + `liczydla?q=proxy'`, {
        params: {
            idPaczki: idPaczki,
            cycles: cycles,
        },
    });
}

export function getPaczki() {
    return axios.get(API_URL + `paczki?q=proxy'`);
}
