import {createSlice} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";

const localThemeKey = "palsoft-theme";
const localTheme : String | null = localStorage.getItem(localThemeKey);
const storedTheme : boolean = (localTheme==='true');

const localDrawerKey = "palsoft-drawer";
const localDrawer : String | null = localStorage.getItem(localDrawerKey);
const storedDrawer : boolean = (localDrawer==='true');

const localDensityKey = "palsoft-density";
const storedDensity : String | null = localStorage.getItem(localDensityKey);


const initialState = {
    darkTheme: storedTheme,
    drawer: storedDrawer,
    density: storedDensity,
};

export const settingsReducer = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        toggleTheme: (state) => {
            state.darkTheme = !state.darkTheme;
            localStorage.setItem(localThemeKey, Boolean(state.darkTheme).toString() );
        },
        toggleDrawer: (state) => {
            state.drawer = !state.drawer;
            localStorage.setItem(localDrawerKey, Boolean(state.drawer).toString() );
        },
        changeDensity: (state, action) => {
            localStorage.setItem(localDensityKey, action.payload);
        },
    },

});

export const {toggleTheme, toggleDrawer, changeDensity} = settingsReducer.actions;

export const selectThemeSwitcher = (state: RootState) => state.themeSwitcher.darkTheme;
export const selectDrawer = (state: RootState) => state.drawer.drawer;
export const selectDensity = (state: RootState) => state.density.density;

export default settingsReducer.reducer;
