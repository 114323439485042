import React from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    Paper,
    Container,
    Grid
} from "@mui/material";
import Copyright from "../components/Copyrights";
import AppBarDrawer from "../components/AppBarDrawer";

function CoursesContent() {
    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBarDrawer title={'Courses'}/>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                                Strona w przygotowaniu :)
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Copyright/>
        </Box>

    );
}

export default function Courses() {
    return <CoursesContent/>;
}
