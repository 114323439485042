import {
    put, call, take, race, cancelled, fork, all, takeLatest, cancel,
} from 'redux-saga/effects';

import {
    uploadFileStart,
    uploadFileSuccess,
    uploadFileError,
    uploadFileCancel,
    uploadFileProgress
} from './uploadActions';
import {uploadApi} from './uploadApi';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as types from "./uploadActionTypes";


function* uploadFile(action: any): any {
    if (action.payload instanceof File) {
        try {
            const channel = yield call(uploadApi, "/api/upload?q=proxy'", action.payload);
            while (true) {
                const {progress = 0, error, success} = yield take(channel);
                if (!(yield cancelled())) {
                    if (error) {
                        yield put(uploadFileError(error));
                        yield put(uploadFileProgress(0));
                        return;
                    }
                    if (success) {
                        toast.success('Plik przesłany poprawnie.');
                        yield put(uploadFileSuccess());
                        yield put(uploadFileProgress(0));
                        return;
                    }
                    yield put(uploadFileProgress(progress));
                }
            }

        } catch (error) {
            yield put(uploadFileError(error));
            yield put(uploadFileProgress(0));
        } finally {
            if (yield cancelled()) {
                yield put(uploadFileError("Upload canceled"));
                yield put(uploadFileProgress(0));
            }
        }
    }
}


function* watchUploadFile(): any {

    while (true) {
        const action = yield take(uploadFileStart);
        yield race([
            call(uploadFile, action),
            take((a: any) => a.type === types.UPLOAD_FILE_CANCEL),
        ]);
    }
}


const uploadSagas = [
    fork(watchUploadFile),
];

export default function* uploadSaga(): any {
    yield all([...uploadSagas]);
}
