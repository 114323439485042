import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    Paper,
    Container,
    Grid,
    LinearProgress,
    Typography,
    Button
} from "@mui/material";
import {
    Group as GroupIcon,
    SportsMartialArts as SportsMartialArtsIcon,
    Accessible as AccessibleIcon,
    Add as AddIcon,
} from "@mui/icons-material";
import Copyright from "../components/Copyrights";
import AppBarDrawer from "../components/AppBarDrawer";
import {
    DataGrid,
    GridFilterModel,
    GridToolbar,
    plPL,
    GridToolbarQuickFilter,
    GridLogicOperator
} from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid/themeAugmentation';
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {clearMessage, selectMessage} from "../reducers/utils/messageReducer";
import {
    loadUsersStart,
    searchUsersStart,
    deleteUserStart,
} from "../reducers/users/usersActions";
import {columns, filterModelOptions, userOptions} from './usersTableConfig';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {selectDensity, selectThemeSwitcher} from "../reducers/utils/settingsReducer";
import NewUserForm from "../components/forms/NewUserForm";
import {logoutAsync} from "../reducers/signIn/signInReducer";
import {useNavigate} from "react-router-dom";
import {User} from "../types/database";
import EditUserForm from "../components/forms/EditUserForm";

function isEmpty(obj: any) {
    for (const x in obj) {
        return false;
    }
    return true;
}

function UsersContent() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(clearMessage());
        dispatch(loadUsersStart())
    }, [dispatch]);

    const message = useAppSelector(selectMessage);
    const darkMode = useAppSelector(selectThemeSwitcher);
    const themeMode = darkMode ? "dark" : "light";
    const density = useAppSelector(selectDensity);
    const {users, loading, error} = useAppSelector((state) => state.usersData);
    const [currentUser, setCurrentUser] = useState<User>({
        idUser: 0,
        email: "",
        password: "",
        imie: "",
        nazwisko: "",
        telefon: "",
        userSecret: "",
        userSecretUrl: "",
        g2fa: false,
        ifActive: false,
        ifInkasent: false,
        ifKoordynator: false,
        ifAdmin: false,
        dataModyfikacji: "",
    });
    const [filterModel, setFilterModel] = useState({
        // items: [filterModelOptions[0]]
        items: new Array(),
    });
    // const [filters, setFilters] = React.useState();
    const [queryOptions, setQueryOptions] = React.useState({});
    const [rows, setRows] = useState([]);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenAdd, setIsOpenAdd] = useState(false);

    const handleDelete = (idUser: number) => {
        dispatch(deleteUserStart(idUser));
    };
    const handleEditVisible = () => {
        setIsOpenEdit(!isOpenEdit);
    };
    const handleEdit = (user: User) => {
        setCurrentUser(user)
    };
    const handleAddVisible = () => {
        setIsOpenAdd(!isOpenAdd);
    };
    const handleRowClick = (idUser: number) => {
    };

    useEffect(() => {
        // console.log('start search 1.')
        // console.log('1 queryOptions', queryOptions)
        if (!isEmpty(queryOptions)) dispatch(searchUsersStart(queryOptions));
    }, [queryOptions]);

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setQueryOptions({filterModel: {...filterModel}});
    }, []);

    const handleFilterChange = (key:string, value:boolean) => {
        let filtersCopy = { ...filterModel};
        filtersCopy.items.push({columnField: key, operatorValue: 'contains', value: value})
        setFilterModel({ ...filtersCopy, [key]: value });
        // console.log('2', filterModel)
        setQueryOptions({filterModel: {...filterModel}});
        // console.log('2', queryOptions)
    };

    const dataColumns = useMemo(() => {
        return columns(handleEdit, handleEditVisible, handleDelete, handleRowClick);
    }, [users]);

    useEffect(() => {
        setRows([...users]);
    }, [users]);

    useEffect(() => {
        if (error) {
            // console.log('error: ', error)
            if (error === 401) {
                dispatch(logoutAsync()).then(() => {
                    navigate('/signIn');
                });
            }
            if (error === 409) {
                toast.error("Email już istnieje.")
            }
        }
    }, [error]);


    // console.log('filtry:', filters);

    return (
        <Box sx={{display: 'flex', height: '100%'}}>
            <CssBaseline/>
            <AppBarDrawer title={'Użytkownicy'}/>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>

                <Paper sx={{p: 1, display: 'flex', flexDirection: 'column', minHeight: '50vh', width: '100%',}}>
                    <Grid container columnGap={5} paddingX={'24px'} paddingY={'12px'}>

                        <Grid item>
                            <Button
                                startIcon={<SportsMartialArtsIcon/>}
                                variant='outlined'
                                onClick={() => handleFilterChange('ifActive', true)}
                            >
                                Aktywni
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<AccessibleIcon/>}
                                variant='outlined'
                                onClick={() => handleFilterChange('ifActive', false)}
                            >
                                Nieaktywni
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<AddIcon/>}
                                variant='outlined'
                                onClick={handleAddVisible}
                            >
                                NOWY
                            </Button>
                        </Grid>
                        <Grid item>
                            {message && <h4>{message}</h4>}
                        </Grid>
                    </Grid>
                    <DataGrid
                        getRowId={
                            (row: any) => row.idUser
                        }
                        disableColumnMenu
                        disableColumnFilter
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterLogicOperator: GridLogicOperator.Or,
                                },
                            },
                        }}
                        slots={{
                            toolbar: GridToolbar,
                            loadingOverlay: LinearProgress,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {debounceMs: 700},
                            },
                            filterPanel: {
                                columnsSort: 'asc',
                                filterFormProps: {
                                    columnInputProps: {
                                        variant: 'outlined',
                                        size: 'small',
                                        sx: {mt: 'auto'},
                                    },
                                    operatorInputProps: {
                                        variant: 'outlined',
                                        size: 'small',
                                        sx: {mt: 'auto'},
                                    },
                                    valueInputProps: {
                                        InputComponentProps: {
                                            variant: 'outlined',
                                            size: 'small',
                                        },
                                    },
                                    deleteIconProps: {
                                        sx: {
                                            '& .MuiSvgIcon-root': {color: '#d32f2f'},
                                        },
                                    },
                                },
                                sx: {
                                    // Customize inputs using css selectors
                                    '& .MuiDataGrid-filterForm': {p: 2},
                                    '& .MuiDataGrid-filterForm":nth-of-type"(even)': {
                                        backgroundColor: (theme: { palette: { mode: string; }; }) =>
                                            theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                                    },
                                    '& .MuiDataGrid-filterFormLinkOperatorInput': {mr: 2},
                                    '& .MuiDataGrid-filterFormColumnInput': {mr: 2, width: 150},
                                    '& .MuiDataGrid-filterFormOperatorInput': {mr: 2},
                                    '& .MuiDataGrid-filterFormValueInput': {width: 200},
                                },
                            }
                        }}
                        loading={loading}
                        autoHeight
                        filterMode='server'
                        rows={rows}
                        columns={dataColumns}
                        isRowSelectable={() => false}
                        isCellEditable={() => false}
                        sx={{
                            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        }}
                        onFilterModelChange={onFilterChange}
                    />
                </Paper>

            </Box>
            <Copyright/>
            <NewUserForm visible={isOpenAdd} setModalVisible={handleAddVisible}/>
            <EditUserForm visible={isOpenEdit} setModalVisible={handleEditVisible} user={currentUser}/>
            <ToastContainer theme={`${themeMode}`}/>
        </Box>

    );
}

export default function Users() {
    return <UsersContent/>;
}
