import React, {useState} from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    Paper,
    Container,
    Grid, Typography, Button
} from "@mui/material";
import Copyright from "../components/Copyrights";
import AppBarDrawer from "../components/AppBarDrawer";
import HomeLayout from "../components/HomeLayout";
import {Bounce, Fade, Slide, Zoom} from "react-awesome-reveal";
import AppFooter from "../components/AppFooter";
import ContactForm from "../components/forms/ContactForm";
import {ToastContainer} from "react-toastify";
import {useAppSelector} from "../app/hooks";
import {selectThemeSwitcher} from "../reducers/utils/settingsReducer";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

const backgroundImage = 'img/web.jpg';

const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

function MobileContent() {

    const darkMode = useAppSelector(selectThemeSwitcher);
    const themeMode = darkMode ? "light" : "dark";

    const [isOpenContact, setIsOpenContact] = useState(false);

    const handleContactVisible = () => {
        setIsOpenContact(!isOpenContact);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBarDrawer title={''}/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                {/*<Toolbar/>*/}
                <HomeLayout
                    sxBackground={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundColor: '#7fc7d9',
                        backgroundPosition: 'center',
                    }}
                >
                    <img
                        style={{display: 'none'}}
                        src={backgroundImage}
                        alt="increase priority"
                    />
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h4"
                        fontFamily="Lynx"
                        sx={{
                            mt:2
                        }}
                    >
                        <Zoom>
                            palsoft
                        </Zoom>
                    </Typography>
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h3"
                        fontFamily="Raleway"
                    >
                        <Zoom>
                            Websites development
                        </Zoom>
                    </Typography>
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h5"
                        fontFamily="Raleway"
                        sx={{mb: 4, mt: {xs: 4, sm: 10}}}
                    >
                        Achieve your business goals with a beautiful custom website.

                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        component="a"
                        onClick={handleContactVisible}
                        sx={{minWidth: 200}}
                    >
                        contact us
                    </Button>
                    <Typography variant="body1" color="inherit" sx={{mt: 5}}>
                        A website is your business card, but also one of the first places people interested in specific
                        services go to. It is important that it expresses the character of the brand as best as
                        possible.
                    </Typography>
                </HomeLayout>

                <Box
                    component="section"
                    sx={{display: 'flex', overflow: 'hidden'}}
                >
                    <Container sx={{mt: 15, mb: 30, display: 'flex', position: 'relative'}}>
                        <Box
                            component="img"
                            src="img/productCurvyLines.png"
                            alt="curvy lines"
                            sx={{pointerEvents: 'none', position: 'absolute', top: -180}}
                        />
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/wordpress.png"
                                        alt="suitcase"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            Wordpress
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="body1">
                                            {
                                                'With over 65% market share, WordPress is the most popular CMS platform for content management and website development, powering over 40% of all websites on the Internet.'
                                            }
                                            {
                                                ''
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/shopping.png"
                                        alt="graph"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            e-commerce
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="body1">
                                            {
                                                'With e-commerce solutions, businesses can operate 24 hours a day, 7 days a week, and reach customers in any time zone. Use your online store to increase your sales.'
                                            }
                                            {
                                                ''
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/ux.png"
                                        alt="clock"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            User Experience (UX) Design
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="body1">
                                            {
                                                'We create websites that provide a consistent and user-friendly experience consistent with your brand identity and business goals. They create visually appealing and intuitive interfaces that increase user engagement, improve navigation, and increase overall user satisfaction.'
                                            }
                                            {
                                                ''
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <AppFooter/>
            </Box>
            <ContactForm visible={isOpenContact} setModalVisible={handleContactVisible}/>
            <ToastContainer theme={`${themeMode}`}/>
        </Box>

    );
}

export default function Mobile() {
    return <MobileContent/>;
}
