import React, {useEffect, useRef, useState} from 'react';
import {
    CssBaseline,
    TextField,
    Avatar,
    Button,
    Link,
    Grid,
    Box,
    Typography,
    Container,
    Modal,
    Paper,
    List,
    ListItem,
    ListItemText,
    AlertProps,
    Alert as MuiAlert, IconButton, Stack, Badge, Tooltip,
} from "@mui/material";
import {
    LockOutlined as LockOutlinedIcon, Logout as LogoutIcon,
    QrCode2 as QrCode2Icon,
    VpnKey as VpnKeyIcon,
    Close as CloseIcon,
    Notifications as NotificationsIcon,
    Home as HomeIcon, EditSharp,
} from "@mui/icons-material";
import {useAppSelector, useAppDispatch} from '../app/hooks';
import {useNavigate} from "react-router-dom";
import {
    selectUser,
    loginAsync,
    logoutAsync,
    getQRCodeAsync,
    registerAsync,
    authAsync
} from '../reducers/signIn/signInReducer';
import {clearMessage, selectMessage} from '../reducers/utils/messageReducer';
import {ThemeSwitcher} from "../components/ThemeSwitcher";
import Copyright from "../components/Copyrights";
import {LoadingButton} from "@mui/lab";
import AuthCode, {AuthCodeProps, AuthCodeRef} from "react-auth-code-input";
import {Logout} from "../components/Logout";
import {styled} from "@mui/material/styles";
import UndoIcon from "@mui/icons-material/Undo";


const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: "5%",
    p: 4,
};

export const StyledEditButton = styled(IconButton)(({theme}) => ({
    // backgroundColor: theme.palette.info.main,
    // color: theme.palette.common.white,
    // borderWidth: '1px',
    // borderColor: theme.palette.info.main,
    // borderStyle: 'solid',
    '&:hover': {
        color: theme.palette.info.main,
        backgroundColor: theme.palette.common.white,
    },
    transition: theme.transitions.create(['background-color', 'transform'], {
        duration: 500,
        easing: theme.transitions.easing.easeInOut,
    }),
}));


export default function SignIn() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const message = useAppSelector(selectMessage);
    const userState = useAppSelector(selectUser);
    const [qrCode, setQrCode] = React.useState('');
    const [openRegister, setOpenRegister] = React.useState(false);
    const [openAuth, setOpenAuth] = React.useState(false);

    const handleRegisterOpen = () => {
        dispatch(clearMessage());
        setOpenRegister(true)
    };

    const handleRegisterClose = () => setOpenRegister(false);

    const handleAuthOpen = () => {
        dispatch(clearMessage());
        setOpenAuth(true)
    };

    const handleAuthClose = () => setOpenAuth(false);

    const AuthInputRef = useRef<AuthCodeRef>(null);
    const [allowedCharacters, setAllowedCharacters] = useState<AuthCodeProps['allowedCharacters']>('numeric');

    function onRegisterCodeChange(res: string) {
        if (res.length === 6) {
            dispatch(registerAsync({code: res, userData: userState}))
                .then((response) => {
                    if (response.type !== 'user/register/rejected') {
                        navigate('/home');
                    }
                });
        }
    }

    function onAuthCodeChange(res: string) {
        if (res.length === 6) {
            if (res.length === 6) {
                dispatch(authAsync({code: res, userData: userState}))
                    .then((response) => {
                        if (response.type !== 'user/auth/rejected') {
                            navigate('/home');
                        }
                    });
            }
        }
    }

    function handleAuthCodeClear() {
        AuthInputRef.current?.clear();
    }

    function handleHome() {
        navigate('/home');
    }


    const handleSubmit = (event: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined; }) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userData = {
            username: data.get('email')!.toString(),
            password: data.get('password')!.toString(),
        }
        const {username, password} = userData;

        dispatch(loginAsync({username, password})).then((response: any) => {

            if (response.payload.userData) {
                const {g2fa} = response.payload.userData.user;
                if (g2fa) {
                    handleAuthOpen();
                } else {
                    dispatch(getQRCodeAsync()).then((response:any) => {
                        setQrCode(response.payload.qrCode);
                        handleRegisterOpen();
                    });

                }
            }

        });

    };

    const [dense, setDense] = useState(false);
    const [secondary, setSecondary] = useState(false);

    return (
        <Container component="main" maxWidth="xs">
            <ThemeSwitcher/>
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Tooltip title="Powrót" arrow>
                    <IconButton size='large' onClick={handleHome}>
                        <UndoIcon sx={{fontSize: 50}}/>
                    </IconButton>
                </Tooltip>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <Link href="#" variant="body2">
                        forgot my password
                    </Link>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 4, mb: 3}}
                    >
                        sign in
                    </Button>
                    {message && (
                        <Typography
                            component="h1"
                            variant="h6"
                            align="center"
                        >
                            {message}
                        </Typography>
                    )}

                </Box>
            </Box>
            <Copyright/>
            <Modal
                open={openRegister}
                onClose={handleRegisterClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={modalStyle}
                >
                    <Paper
                        sx={{
                            p: 2,
                            mt: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'auto',
                        }}
                    >
                        <Grid
                            container
                            spacing={5}
                            mt={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item={true} xs>
                            </Grid>
                            <Grid item={true} xs="auto">
                                <Typography component="h1" variant="h5">
                                    First 2FA login
                                </Typography>
                            </Grid>
                            <Grid item={true} xs>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={6}
                                >
                                    <IconButton onClick={handleRegisterClose}>
                                        <CloseIcon sx={{fontSize: "30px"}}/>
                                    </IconButton>
                                </Stack>
                            </Grid>
                        </Grid>

                        <List dense={dense}>
                            <ListItem>
                                <ListItemText
                                    primary="1. Install Google Authenticator (IOS - Android)."
                                    secondary={secondary ? 'Secondary text' : null}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="2. In the Google Authenticator app select the icon „+”."
                                    secondary={secondary ? 'Secondary text' : null}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="3. Select 'Scan barcode (or QR code)' and use your phone's camera
                                to scan the barcode below."
                                    secondary={secondary ? 'Secondary text' : null}
                                />
                            </ListItem>
                        </List>

                        <Box
                            sx={{
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src={qrCode} alt="Logo"/>
                        </Box>
                        <Box
                            sx={{
                                p: 2,
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: 'auto',
                            }}
                        >
                            <List dense={dense}>
                                <ListItem>
                                    <ListItemText
                                        primary="A następnie wpisz poniżej 6 cyfrowy kod z urządzenia.:"
                                        secondary={secondary ? 'Secondary text' : null}
                                    />
                                </ListItem>
                            </List>
                            <AuthCode
                                ref={AuthInputRef}
                                allowedCharacters={allowedCharacters}
                                onChange={onRegisterCodeChange}
                                containerClassName='container'
                                inputClassName='input2fa'
                            />
                            <Button onClick={handleAuthCodeClear}>czyść</Button>
                            {message && (
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    align="center"
                                    sx={{mt: 2}}
                                >
                                    {message}
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </Modal>

            <Modal
                open={openAuth}
                onClose={handleAuthClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={modalStyle}
                >
                    <Paper
                        sx={{
                            p: 2,
                            mt: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'auto',
                        }}
                    >
                        <Grid
                            container
                            spacing={5}
                            mt={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item={true} xs>
                            </Grid>
                            <Grid item={true} xs="auto">
                                <Typography component="h1" variant="h5">
                                    Autoryzacja 2FA
                                </Typography>
                            </Grid>
                            <Grid item={true} xs>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={6}
                                >
                                    <IconButton onClick={handleAuthClose}>
                                        <CloseIcon sx={{fontSize: "30px"}}/>
                                    </IconButton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <List dense={dense}>
                            <ListItem>
                                <ListItemText
                                    primary="Otwórz Google Authenticator na smartfonie"
                                    secondary={secondary ? 'Secondary text' : null}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="i wpisz poniżej 6 cyfrowy kod z urządzenia:"
                                    secondary={secondary ? 'Secondary text' : null}
                                />
                            </ListItem>
                        </List>
                        <Box
                            sx={{
                                p: 2,
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: 'auto',
                            }}
                        >
                            <AuthCode
                                ref={AuthInputRef}
                                allowedCharacters={allowedCharacters}
                                onChange={onAuthCodeChange}
                                containerClassName='container'
                                inputClassName='input2fa'
                            />
                            <Button onClick={handleAuthCodeClear}>czyść</Button>
                            {message && (
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    align="center"
                                    sx={{mt: 2}}
                                >
                                    {message}
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </Modal>
        </Container>
    );
}