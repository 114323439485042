import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import emailjs from '@emailjs/browser';
import {toast, useToast} from "react-toastify";


interface StateType {
    message: string,
}

const initialState: StateType = {
    message: '',
};


export const sendMessageAsync = createAsyncThunk(
    "contact/sendMessage",
    async (data: any, thunkAPI) => {
        try {


            const templateParams = {
                name: data.name,
                email: data.email,
                message: data.message,
            };
            // console.log("templateParams", templateParams)
            // @ts-ignore
            emailjs.send('service_xrlrwfb', 'template_ek1quk9', templateParams, '7Ws7w_fzyqH4Qe8EF')
                .then((response: any) => {
                    toast.success("Message sent :)", {autoClose: 2000});
                    // console.log('SUCCESS!', response.status, response.text);
                }, (error: any) => {
                    toast.error("Message not sent :(", {autoClose: 2000});
                    // console.log('FAILED...', error);
                });

        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue('');
        }
    }
);

const contactReducer = createSlice({
    name: "contact",
    initialState,
    reducers: {

        setMessage: (state, action) => {
            return {message: action.payload};
        },
        clearMessage: () => {
            return {message: ""};
        },
    },
});
export const selectMessage = (state: RootState) => state.message.message;

export const {setMessage, clearMessage} = contactReducer.actions

export default contactReducer.reducer;