import React, {useState} from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    Paper,
    Container,
    Grid, Typography, Button
} from "@mui/material";
import Copyright from "../components/Copyrights";
import AppBarDrawer from "../components/AppBarDrawer";
import HomeLayout from "../components/HomeLayout";
import {Bounce, Fade, Slide, Zoom} from "react-awesome-reveal";
import AppFooter from "../components/AppFooter";
import ContactForm from "../components/forms/ContactForm";
import {ToastContainer} from "react-toastify";
import {useAppSelector} from "../app/hooks";
import {selectThemeSwitcher} from "../reducers/utils/settingsReducer";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

const backgroundImage = 'img/web10.jpg';

const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

function MobileContent() {

    const darkMode = useAppSelector(selectThemeSwitcher);
    const themeMode = darkMode ? "light" : "dark";

    const [isOpenContact, setIsOpenContact] = useState(false);

    const handleContactVisible = () => {
        setIsOpenContact(!isOpenContact);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBarDrawer title={''}/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                {/*<Toolbar/>*/}
                <HomeLayout
                    sxBackground={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundColor: '#7fc7d9',
                        backgroundPosition: 'center',
                    }}
                >
                    <img
                        style={{display: 'none'}}
                        src={backgroundImage}
                        alt="increase priority"
                    />
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h4"
                        fontFamily="Lynx"
                        sx={{
                            mt:2
                        }}
                    >
                        <Zoom>
                            palsoft
                        </Zoom>
                    </Typography>
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h3"
                        fontFamily="Raleway"
                    >
                        <Zoom>
                            Full-stack mobile app development
                        </Zoom>
                    </Typography>
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h5"
                        fontFamily="Raleway"
                        sx={{mb: 4, mt: {xs: 4, sm: 10}}}
                    >
                        We develop apps for both iOS and Android with backend app.

                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        component="a"
                        onClick={handleContactVisible}
                        sx={{minWidth: 200}}
                    >
                        contact us
                    </Button>
                    <Typography variant="body1" color="inherit" sx={{mt: 5}}>
                        We specialize in cross-platform app development, and we believe it's the best solution for most
                        cases.
                    </Typography>
                </HomeLayout>

                <Box
                    component="section"
                    sx={{display: 'flex', overflow: 'hidden'}}
                >
                    <Container sx={{mt: 15, mb: 30, display: 'flex', position: 'relative'}}>
                        <Box
                            component="img"
                            src="img/productCurvyLines.png"
                            alt="curvy lines"
                            sx={{pointerEvents: 'none', position: 'absolute', top: -180}}
                        />
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/flutter_app.png"
                                        alt="suitcase"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            Flutter
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="body1">
                                            {
                                                'Flutter is the newest cross-platform framework based on Dart language that enables building applications for mobile devices (iOS & Android) and desktop (Mac & PC), and web browsers from the same codebase.'
                                            }
                                            {
                                                ''
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/backend.png"
                                        alt="graph"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            Backend
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="body1">
                                            {
                                                'Without a functional backend, your options will be limited, no matter how engaging the user interface is. The backend facilitates data exchange and communication, helping the mobile application perform computationally intensive tasks.'
                                            }
                                            {
                                                ''
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/dashboard.png"
                                        alt="clock"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            Admin dashboard
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="body1">
                                            {
                                                'Admin panel is basically the heart of your product. It is an interface, that allows you to manipulate and manage your app\'s data to power user-related functions, handle transactions, and track performance metrics.'
                                            }
                                            {
                                                ''
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <AppFooter/>
            </Box>
            <ContactForm visible={isOpenContact} setModalVisible={handleContactVisible}/>
            <ToastContainer theme={`${themeMode}`}/>
        </Box>

    );
}

export default function Mobile() {
    return <MobileContent/>;
}
