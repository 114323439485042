import React, {useState} from "react";
import {
    alpha,
    Menu,
    Drawer as MuiDrawer,
    AppBarProps as MuiAppBarProps,
    AppBar as MuiAppBar,
    styled,
    Box,
    Button,
    IconButton,
    MenuItem,
    Toolbar,
    Typography,
    Badge,
    Divider,
    List, Tooltip, ListItemIcon, ListItemText, ListItemButton,
} from "@mui/material";
import {
    SettingsOutlined,
    ArrowDropDownOutlined,
    Menu as MenuIcon,
    Notifications as NotificationsIcon,
    ChevronLeft as ChevronLeftIcon, Login, Apps,
} from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import {ThemeSwitcher} from "./ThemeSwitcher";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {selectIsLoggedIn, logoutAsync, selectUser} from "../reducers/signIn/signInReducer";
import {MainListItems, SecondaryListItems} from "./MenuItems";
import {selectDrawer, toggleDrawer} from "../reducers/utils/settingsReducer";
import {Logout} from "./Logout";
import {SessionTimeout} from './SessionTimeout';
import {useNavigate} from "react-router-dom";


const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function AppBarDrawer(props: any) {

    const open = useAppSelector(selectDrawer);
    const dispatch = useAppDispatch();

    const userData = useAppSelector(selectUser);
    const isLoggedInState = useAppSelector(selectIsLoggedIn);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event: any) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <Box sx={{display: 'flex'}}>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ...(open && {
                            justifyContent: 'flex-end'
                        }),
                        ...(!open && {
                            justifyContent: 'flex-start',
                        }),
                        px: [1],
                    }}
                >
                    <IconButton
                        onClick={() => dispatch(toggleDrawer())}
                        sx={{
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <IconButton
                        onClick={() => dispatch(toggleDrawer())}
                        sx={{
                            ...(!open && {display: 'none'}),
                        }}
                    >
                        <ChevronLeftIcon/>
                    </IconButton>
                </Toolbar>
                <Divider/>
                <List component="nav">
                    <MainListItems/>
                    <Divider sx={{my: 1}}/>
                    <SecondaryListItems/>
                </List>
                <Box
                    sx={{
                        mb: "5rem",
                        height: "100%",
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'flex-start',
                    }}
                >
                    <ThemeSwitcher/>
                </Box>
            </Drawer>
        </Box>
    );
}