import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {
    TextField,
    Button,
    Grid,
    Box,
    Typography,
    Modal,
    Paper,
    IconButton,
    Stack,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel
} from "@mui/material";
import {
    Close as CloseIcon,
} from "@mui/icons-material";
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {clearMessage, selectMessage} from '../../reducers/utils/messageReducer';
import {useForm, SubmitHandler, Controller, DefaultValues} from "react-hook-form";
import {createUserStart, updateUserStart} from "../../reducers/users/usersActions";
import {User} from "../../types/database";

const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: "5%",
    p: 4,
};

interface ModalProps {
    visible: boolean,
    setModalVisible: (active: boolean) => void,
    user: User,
}

export default function EditUserForm({visible, setModalVisible, user}: ModalProps) {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const defaultValues: DefaultValues<User> = {
        ...user
    };

    const {register, setValue, handleSubmit, watch, reset, control, formState: {errors}} = useForm<User>({
        defaultValues
    });

    useEffect(() => {
        if (defaultValues) {
            setValue("idUser", user.idUser, {shouldDirty: true});
            setValue("email", user.email, {shouldDirty: true});
            setValue("password", user.password, {shouldDirty: true});
            setValue("imie", user.imie, {shouldDirty: true});
            setValue("nazwisko", user.nazwisko, {shouldDirty: true});
            setValue("telefon", user.telefon, {shouldDirty: true});
            setValue("ifActive", user.ifActive, {shouldDirty: true});
            setValue("ifInkasent", user.ifInkasent, {shouldDirty: true});
            setValue("ifKoordynator", user.ifKoordynator, {shouldDirty: true});
            setValue("ifAdmin", user.ifAdmin, {shouldDirty: true});
        }
    }, [defaultValues, setValue]);


    const onSubmit: SubmitHandler<User> = data => {
        setModalVisible(false);
        dispatch(updateUserStart(data))
    }

    const message = useAppSelector(selectMessage);

    function handleModalClose() {
        setModalVisible(false);
    }

    return (
        <Modal
            open={visible}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={modalStyle}
            >
                <Paper
                    sx={{
                        p: 1,
                        mt: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                    }}
                >
                    <Grid
                        container
                        spacing={5}
                        mt={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item={true} xs>
                        </Grid>
                        <Grid item={true} xs="auto">
                            <Typography component="h1" variant="h5">
                                Edycja użytkownika:
                            </Typography>
                        </Grid>
                        <Grid item={true} xs>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={6}
                            >
                                <IconButton onClick={handleModalClose}>
                                    <CloseIcon sx={{fontSize: "30px"}}/>
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>


                        <Controller
                            control={control}
                            name={"email"}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register("email")}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    autoComplete="email"
                                    autoFocus
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={"password"}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register("password")}
                                    margin="normal"
                                    fullWidth
                                    id="password"
                                    label="Hasło"
                                    name="password"
                                    autoComplete="new-password"
                                    autoFocus
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={"imie"}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register("imie")}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="imie"
                                    label="Imię"
                                    name="imie"
                                    autoComplete="given-name"
                                    autoFocus
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={"nazwisko"}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register("nazwisko")}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="nazwisko"
                                    label="Nazwisko"
                                    name="nazwisko"
                                    autoComplete="family-name"
                                    autoFocus
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={"telefon"}
                            render={({field: {onChange, value}}) => (
                                <TextField
                                    {...register("telefon")}
                                    margin="normal"
                                    fullWidth
                                    id="telefon"
                                    label="Telefon"
                                    name="telefon"
                                    autoComplete="tel"
                                    autoFocus
                                />
                            )}
                        />

                        <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">Uprawnienia</FormLabel>
                            <FormGroup>
                                <Stack spacing={3} direction="row">
                                <Controller
                                    control={control}
                                    name={"ifActive"}
                                    render={({field: {onChange, value}}) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={value} onChange={onChange}/>
                                            }
                                            label={"Aktywny"}/>

                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={"ifInkasent"}
                                    render={({field: {onChange, value}}) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={value} onChange={onChange}/>
                                            }
                                            label={"Inkasent"}/>

                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={"ifKoordynator"}
                                    render={({field: {onChange, value}}) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={value} onChange={onChange}/>
                                            }
                                            label={"Koordynator"}/>

                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={"ifAdmin"}
                                    render={({field: {onChange, value}}) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={value} onChange={onChange}/>
                                            }
                                            label={"Administrator"}/>

                                    )}
                                />
                                </Stack>
                            </FormGroup>
                        </FormControl>
                        <Stack spacing={3} direction="row">
                            <Button
                                variant="outlined"
                                size="medium"
                                onClick={() => {
                                    reset(defaultValues);
                                }}
                            >
                                przywróć
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                size="medium"
                                // fullWidth
                            >
                                ZAPISZ ZMIANY
                            </Button>
                        </Stack>
                    </form>
                    <Box
                        sx={{
                            p: 2,
                            mt: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: 'auto',
                        }}
                    >

                        {message && (
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                sx={{mt: 2}}
                            >
                                {message}
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}