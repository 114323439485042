
import {
    IconButton,
    Tooltip,
    Grid,
} from "@mui/material";
import {
    Check as CheckIcon,
    Close as CloseIcon,
} from "@mui/icons-material";
import DeleteButton from '../components/buttons/DeleteButton';
import EditButton from '../components/buttons/EditButton';
import ViewButton from '../components/buttons/ViewButton';

export const userOptions = [
    {label: 'Active', value: 1},
    {label: 'Inactive', value: 0},
];

export const columns = (handleEdit, handleEditVisible, handleDelete, handleSelect) => [
    {field: 'idUser', headerName: 'id', minWidth: 50, flex: 0.5, type: 'number', filterable: false,},
    {field: 'imie', headerName: 'imię', minWidth: 100, flex: 2, type: 'string',filterable: true,},
    {field: 'nazwisko', headerName: 'nazwisko', minWidth: 100, flex: 2, type: 'string',filterable: true,},
    {field: 'email', headerName: 'email', minWidth: 200, flex: 2, type: 'string',filterable: true,},
    {field: 'telefon', headerName: 'telefon', minWidth: 100, flex: 2, type: 'string',filterable: false,},
    {
        field: 'ifActive',
        type: 'boolean',
        headerName: 'aktywny',
        minWidth: 50,
        flex: 2,
        filterable: false,
        renderCell: (params) => {
            return (
                <>
                            {params.value ?
                                <CheckIcon style={{fill: "green"}}/>
                                :
                                <CloseIcon style={{fill: "red"}}/>
                            }
                </>
            )
        }
    },
    {
        field: 'ifInkasent',
        type: 'boolean',
        headerName: 'inkasent',
        minWidth: 50,
        flex: 2,
        filterable: false,
        renderCell: (params) => {
            return (
                <>
                            {params.value ?
                                <CheckIcon style={{fill: "green"}}/>
                                :
                                <CloseIcon style={{fill: "red"}}/>
                            }
                </>
            )
        }
    },
    {
        field: 'ifKoordynator',
        type: 'boolean',
        headerName: 'koordynator',
        minWidth: 50,
        flex: 2,
        filterable: false,
        renderCell: (params) => {
            return (
                <>
                            {params.value ?
                                <CheckIcon style={{fill: "green"}}/>
                                :
                                <CloseIcon style={{fill: "red"}}/>
                            }
                </>
            )
        }
    },
    {
        field: 'ifAdmin',
        type: 'boolean',
        headerName: 'admin',
        minWidth: 50,
        flex: 2,
        filterable: false,
        renderCell: (params) => {
            return (
                <>
                            {params.value ?
                                <CheckIcon style={{fill: "green"}}/>
                                :
                                <CloseIcon style={{fill: "red"}}/>
                            }
                </>
            )
        }
    },
    {
        field: 'dataModyfikacji',
        headerName: 'utworzony',
        type: 'string',
        filterable: false,
        width: 200,
        valueGetter: (params) => new Date(params.row.dataModyfikacji).toLocaleString(),
    },
    {
        field: 'actions',
        // type: 'actions',
        headerName: 'operacje',
        minWidth: 220,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
            return (
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <EditButton
                            onClick={async (e) => {
                                e.ignore = true;
                                await handleEdit(params.row);
                                handleEditVisible();
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <DeleteButton
                            onClick={(e) => {
                                e.ignore = true;
                                handleDelete(params.id);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <ViewButton
                            onClick={(e) => {
                                e.ignore = true;
                                handleSelect(params.id);
                            }}
                        />
                    </Grid>
                </Grid>
            );
        },
    },
];

export const filterModelOptions = [
    {columnField: 'imie', operatorValue: 'contains', value: ''},
    {columnField: 'nazwisko', operatorValue: 'contains', value: ''},
    {columnField: 'email', operatorValue: 'contains', value: ''},
];
