import {createTheme} from "@mui/material/styles";
import {plPL} from "@mui/x-data-grid";

export const lightTheme = createTheme({
        palette: {
            mode: "light",
            // background: {
            //     paper: "#f2f2f2",
            // },
            // text: {
            //     primary: "#11111",
            // },

        },

    },
    plPL,
);

export const darkTheme = createTheme({
        palette: {
            mode: "dark",
            // background: {
            //     paper: "#5e4949",
            // },
            // text: {
            //     primary: "#fff",
            // },
        },
    },
    plPL,
);