import axios from "axios";

const API_URL = "/api/user/";

export function login({username, password}: { username: string, password: string }) {
    return axios
        .post(API_URL + "auth?q=proxy'", {
                username,
                password,
            },
            {withCredentials: true})
        .then((response) => {
            return response.data;
        });
}


export function logout() {

}

export function g2faGetQRCode() {
    return axios
        .post(API_URL + "g2faGetQRCode?q=proxy'",
            {withCredentials: true})
        .then((response) => {
            return response.data;
        });
}

export function g2faRegister({code}: { code: string}) {
    return axios
        .post(API_URL + "g2faRegister?q=proxy'", {
                code,
            },
            {withCredentials: true})
        .then((response) => {
            return response.data;
        });
}

export function g2faAuth({code}: { code: string}) {
    return axios
        .post(API_URL + "g2faAuth?q=proxy'", {
                code,
            },
            {withCredentials: true})
        .then((response) => {
            return response.data;
        });
}