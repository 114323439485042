import React, {useState} from 'react';
import {
    ListItemIcon,
    ListItemText,
    ListItemButton,
    ListSubheader, Tooltip,

} from "@mui/material";
import {
    Dashboard as DashboardIcon,
    People as PeopleIcon,
    Assignment as AssignmentIcon,
    Place as PlaceIcon,
    EditRoad as EditRoadIcon,
    School as SchoolIcon,
    Info as InfoIcon,
    Home as HomeIcon, EMobiledata, FourGMobiledata, InstallMobile, MobiledataOff, MobileFriendly, Web, Apps,
} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppSelector} from "../app/hooks";
import {selectUser} from "../reducers/signIn/signInReducer";
import {ThemeSwitcher} from "./ThemeSwitcher";

export const MainListItems = () => {
    const navigate = useNavigate();
    const userState = useAppSelector(selectUser);
    const [userRole, setUserRole] = useState('guest')

    return (
        <React.Fragment>
            <Tooltip title="About us" arrow  placement="right-start">
                <ListItemButton onClick={() => {
                    navigate(`/home`)
                }}>
                    <ListItemIcon>
                        <HomeIcon/>
                    </ListItemIcon>
                    <ListItemText primary="About us"/>
                </ListItemButton>
            </Tooltip>
            <Tooltip title="Mobile apps" arrow  placement="right-start">
            <ListItemButton onClick={() => {
                navigate(`/mobile`)
            }}>
                <ListItemIcon>
                    <MobileFriendly/>
                </ListItemIcon>
                <ListItemText primary="Mobile apps"/>
            </ListItemButton>
            </Tooltip>
            <Tooltip title="Web apps" arrow  placement="right-start">
            <ListItemButton onClick={() => {
                navigate(`/webapps`)
            }}>
                <ListItemIcon>
                    <Apps/>
                </ListItemIcon>
                <ListItemText primary="Web apps"/>
            </ListItemButton>
            </Tooltip>
            <Tooltip title="Websites" arrow  placement="right-start">
            <ListItemButton onClick={() => {
                navigate(`/websites`)
            }}>
                <ListItemIcon>
                    <Web/>
                </ListItemIcon>
                <ListItemText primary="Websites"/>
            </ListItemButton>
            </Tooltip>
            <Tooltip title="Courses" arrow  placement="right-start">
            <ListItemButton onClick={() => {
                navigate(`/courses`)
            }}>
                <ListItemIcon>
                    <SchoolIcon/>
                </ListItemIcon>
                <ListItemText primary="Courses"/>
            </ListItemButton>
            </Tooltip>
            {userRole === 'admin'
                ? <ListItemButton onClick={() => {
                    navigate(`/users`)
                }}>
                    <ListItemIcon>
                        <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Users"/>
                </ListItemButton>
                : <></>
            }

        </React.Fragment>
    )
}

export const SecondaryListItems = () => {
    const navigate = useNavigate();
    const userState = useAppSelector(selectUser);
    return (
        <React.Fragment>
            <ListSubheader component="div" inset>


            </ListSubheader>
        </React.Fragment>
    )
}
