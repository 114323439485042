import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getLiczydla, getPaczki} from "./ppeApi";
import {setMessage} from "../utils/messageReducer";
import {RootState} from "../../app/store";
import {Liczydlo, Paczka} from "../../types/database";


interface StateType {
    liczydla: Liczydlo[],
    paczki: Paczka[],
    currentPackage: Paczka | null,
    loading: boolean,
    error: {
        status: number | null,
        message: string | null,
    }
}

const initialState: StateType = {
    liczydla: [],
    paczki: [],
    currentPackage: null,
    loading: false,
    error: {
        status: null,
        message: null,
    }
};

export const getLiczydlaAsync = createAsyncThunk(
    "ppe/liczydla",
    async ({idPaczki, cycles}: { idPaczki: number, cycles: string[] }, thunkAPI) => {
        try {
            const data = await getLiczydla({idPaczki: idPaczki, cycles: cycles});
            return {liczydlaData: data};

        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue('');
        }
    }
);

export const getPaczkiAsync = createAsyncThunk(
    "ppe/paczki",
    async (_, thunkAPI) => {
        try {
            const data = await getPaczki();
            return {paczkiData: data};
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue('');
        }
    }
);


export const ppeReducer = createSlice({
    name: 'ppe',
    initialState,
    reducers: {
        setCurrentPackage: (state, action) => {
            if (action.payload.idPaczki) state.currentPackage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLiczydlaAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLiczydlaAsync.fulfilled, (state, action) => {
                state.loading = false;
                const {status} = action.payload.liczydlaData;

                switch (status) {
                    case 200:
                        const {liczydla} = action.payload.liczydlaData.data;
                        console.log('liczydla1', liczydla)
                        state.liczydla = action.payload.liczydlaData.data.liczydla;
                        break;
                    case 204:
                        state.paczki = [];
                        break;
                    default:
                        state.paczki = [];
                }


                console.log('liczydla1', status)
                console.log('liczydla2', action.payload.liczydlaData.data.liczydla)

            })
            .addCase(getLiczydlaAsync.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getPaczkiAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPaczkiAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.paczki = action.payload.paczkiData.data.paczki;
            })
            .addCase(getPaczkiAsync.rejected, (state) => {
                state.loading = false;
            })


    }
});

export const {setCurrentPackage} = ppeReducer.actions;

export const selectPackages = (state: RootState) => state.ppe.paczki;
export const selectLiczydla = (state: RootState) => state.ppe.liczydla;
export const selectCurrentPackage = (state: RootState) => state.ppe.currentPackage;
export const selectLoading = (state: RootState) => state.ppe.loading;
export const selectError = (state: RootState) => state.ppe.error;

export default ppeReducer.reducer;

