import React, {useEffect} from 'react';
import SignIn from "./pages/SignIn";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import {ThemeProvider} from '@mui/material/styles';
import {useAppSelector, useAppDispatch} from './app/hooks';
import {selectThemeSwitcher} from "./reducers/utils/settingsReducer";
import {darkTheme, lightTheme} from "./assets/themes";
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {selectIsLoggedIn, selectUser} from "./reducers/signIn/signInReducer";
import ProtectedRoute, {ProtectedRouteProps} from "./components/ProtectedRoute";
import Users from "./pages/Users";
import Mobile from "./pages/Mobile";
import Webapps from "./pages/Webapps";
import Websites from "./pages/Websites";
import Courses from "./pages/Courses";

function App() {

    // const [cookiesUSER, setCookieUSER, removeCookieUSER] = useCookies(['e-inkas-USER']);
    // const cookieUSERState = useAppSelector(selectCookieUSER);

    const themeSwitcherState = useAppSelector(selectThemeSwitcher);
    const isLoggedInState = useAppSelector(selectIsLoggedIn);

     // console.log("App render")

    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        isLoggedIn: isLoggedInState,
        loginPath: '/SignIn',
    };

    return (
        <div className="App">
            <BrowserRouter>
                <ThemeProvider theme={themeSwitcherState ? lightTheme : darkTheme}>

                    <Routes>
                        <Route path="/" element={<Navigate to="/home" replace/>}/>
                        <Route path='/home' element={<Home/>}/>
                        <Route path='/mobile' element={<Mobile/>}/>
                        <Route path='/webapps' element={<Webapps/>}/>
                        <Route path='/websites' element={<Websites/>}/>
                        <Route path='/courses'
                               element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Courses/>}/>}/>
                        <Route path='/users'
                               element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Users/>}/>}/>
                        <Route path="/signIn" element={<SignIn/>}/>
                        <Route path='*' element={<NotFound/>}/>
                    </Routes>

                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
