import React, {useState} from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    Paper,
    Container,
    Grid, Typography, Button
} from "@mui/material";
import Copyright from "../components/Copyrights";
import AppBarDrawer from "../components/AppBarDrawer";
import HomeLayout from "../components/HomeLayout";
import {Bounce, Fade, Slide, Zoom} from "react-awesome-reveal";
import AppFooter from "../components/AppFooter";
import ContactForm from "../components/forms/ContactForm";
import {ToastContainer} from "react-toastify";
import {useAppSelector} from "../app/hooks";
import {selectThemeSwitcher} from "../reducers/utils/settingsReducer";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

const backgroundImage = 'img/web2.jpg';

const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

function MobileContent() {

    const darkMode = useAppSelector(selectThemeSwitcher);
    const themeMode = darkMode ? "light" : "dark";

    const [isOpenContact, setIsOpenContact] = useState(false);

    const handleContactVisible = () => {
        setIsOpenContact(!isOpenContact);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBarDrawer title={''}/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                {/*<Toolbar/>*/}
                <HomeLayout
                    sxBackground={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundColor: '#7fc7d9',
                        backgroundPosition: 'center',
                    }}
                >
                    <img
                        style={{display: 'none'}}
                        src={backgroundImage}
                        alt="increase priority"
                    />
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h4"
                        fontFamily="Lynx"
                        sx={{
                            mt:2
                        }}
                    >
                        <Zoom>
                            palsoft
                        </Zoom>
                    </Typography>
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h3"
                        fontFamily="Raleway"
                    >
                        <Zoom>
                            Full-stack web app development
                        </Zoom>
                    </Typography>
                    <Typography
                        color="inherit"
                        align="center"
                        variant="h5"
                        fontFamily="Raleway"
                        sx={{mb: 4, mt: {xs: 4, sm: 10}}}
                    >
                        Are you planning to build a content-based platform that users will use interactively and that
                        must offer them a full range of usability?

                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        component="a"
                        onClick={handleContactVisible}
                        sx={{minWidth: 200}}
                    >
                        contact us
                    </Button>
                    <Typography variant="body1" color="inherit" sx={{mt: 5}}>
                        Web applications increase the efficiency of processes by standardizing data and introducing
                        quality control at every stage of these processes.
                    </Typography>
                </HomeLayout>

                <Box
                    component="section"
                    sx={{display: 'flex', overflow: 'hidden'}}
                >
                    <Container sx={{mt: 15, mb: 30, display: 'flex', position: 'relative'}}>
                        <Box
                            component="img"
                            src="img/productCurvyLines.png"
                            alt="curvy lines"
                            sx={{pointerEvents: 'none', position: 'absolute', top: -180}}
                        />
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/react.png"
                                        alt="suitcase"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            React
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="body1">
                                            {
                                                'React and its modern frameworks for Frontend Development guarantees the best results. '
                                            }
                                            {
                                                'Written in JavaScript, maintained by Facebook, used by globally recognised brands like Airbnb, Tesla, and Netflix – React holds more than 60% of the market share. '
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/nodejs.png"
                                        alt="graph"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            Node.js
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"}>
                                        <Typography variant="body1">
                                            {
                                                'With Node.js as backend development, you can be sure to receive an app that’s highly functional and easily scalable, while remaining lightweight at the same time. Node.js and its modern frameworks is being considered one of the best for microservice architecture.'
                                            }
                                            {
                                                ''
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={item}>
                                    <Box
                                        component="img"
                                        src="img/database.png"
                                        alt="clock"
                                        sx={{height: 100}}
                                    />
                                    <Typography variant="h5" sx={{my: 5}} fontFamily="Raleway" align="center">
                                        <Bounce>
                                            Database & Cloud
                                        </Bounce>
                                    </Typography>
                                    <Slide direction={"up"} >
                                        <Typography variant="body1">
                                            {
                                                'Together, we\'ll establish an optimal cloud workflow that will help you reduce cloud expenses, automate infrastructure monitoring, and increase architectural agility. We will also select an adequate database for your data.'
                                            }
                                            {
                                                ''
                                            }
                                        </Typography>
                                    </Slide>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <AppFooter/>
            </Box>
            <ContactForm visible={isOpenContact} setModalVisible={handleContactVisible}/>
            <ToastContainer theme={`${themeMode}`}/>
        </Box>

    );
}

export default function Mobile() {
    return <MobileContent/>;
}
