import * as React from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    Paper,
    Container,
    Grid,
    Typography,
    Button,
    Link,
    TextField,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    FormControl,
    FormLabel,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useEffect} from "react";
import {clearMessage, selectMessage} from "../reducers/utils/messageReducer";
import {getQRCodeAsync, loginAsync} from "../reducers/signIn/signInReducer";


export default function AppFooter() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const message = useAppSelector(selectMessage);

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const handleSubmit = (event: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined; }) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const messageData = {
            message: data.get('message')!.toString(),
            email: data.get('email')!.toString(),
        }
        const {message, email} = messageData;
        // console.log(message, email)

        // dispatch(loginAsync({message, email})).then((response: any) => {
        //
        //     if (response.payload.userData) {
        //         const {g2fa} = response.payload.userData.user;
        //         if (g2fa) {
        //             handleAuthOpen();
        //         } else {
        //             dispatch(getQRCodeAsync()).then((response:any) => {
        //                 setQrCode(response.payload.qrCode);
        //                 handleRegisterOpen();
        //             });
        //
        //         }
        //     }
        //
        // });

    };

    return (
        <Typography
            component="footer"
            sx={{display: 'flex'}}
        >
            <Container sx={{my: 8, display: 'flex'}}>
                <Grid container spacing={5}>

                    <Grid item xs={10} sm={5} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Links
                        </Typography>
                        <Box component="ul" sx={{m: 0, listStyle: 'none', p: 0}}>
                            <ListItemButton
                                onClick={() => {
                                    navigate(`/mobile`)
                                }}
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    boxShadow: 0,
                                    borderRadius: 0,
                                }}
                            >
                                <ListItemText
                                    primary={"Mobile applications"}
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }
                                    }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                onClick={() => {
                                    navigate(`/webapps`)
                                }}
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    boxShadow: 0,
                                    borderRadius: 0,
                                }}
                            >
                                <ListItemText
                                    primary={"Web applications"}
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }
                                    }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                onClick={() => {
                                    navigate(`/websites`)
                                }}
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    boxShadow: 0,
                                    borderRadius: 0,
                                }}
                            >
                                <ListItemText
                                    primary={"Websites"}
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }
                                    }}
                                />
                            </ListItemButton>
                            <ListItemButton
                                onClick={() => {
                                    navigate(`/courses`)
                                }}
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    boxShadow: 0,
                                    borderRadius: 0,
                                }}
                            >
                                <ListItemText
                                    primary={"Courses for students"}
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact
                        </Typography>
                        <Box component="ul" sx={{m: 0, listStyle: 'none', p: 0}}>
                            <ListItemButton
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    boxShadow: 0,
                                    borderRadius: 0,
                                }}
                                href={'mailto:contact@palsoft.dev'}
                            >
                                <ListItemText
                                    primary={"contact@palsoft.dev"}
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }
                                    }}
                                />
                            </ListItemButton>

                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <Typography  bottom="10px" right="30px" variant="body2" color="text.secondary" align="center">
                            {'Copyright © '}
                            <Link color="inherit" href="https://palsoft.dev/">
                                PALSOFT
                            </Link>{' '}
                            {new Date().getFullYear()}
                            {'.'}
                        </Typography>

                    </Grid>

                </Grid>
            </Container>
        </Typography>
    );
}
