import React from 'react';
import {
    CssBaseline,
    Box,
    Toolbar,
    Paper,
    Container,
    Grid,
    Typography,
    Avatar,
    SvgIcon, IconButton
} from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import Copyright from "../components/Copyrights";
import AppBarDrawer from "../components/AppBarDrawer";
import {useNavigate} from "react-router-dom";

function NotFoundContent() {

    const navigate = useNavigate();


    function handleHomeClick() {
        navigate('/home');
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{p: 2, alignItems: "center", display: 'flex', flexDirection: 'column'}}>

                                    <IconButton onClick={handleHomeClick}>
                                        <UndoIcon sx={{fontSize: 50}}/>
                                    </IconButton>

                                <Typography component="h1" variant="h5">
                                    nie ma takiej strony
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Copyright/>
        </Box>

    );
}

export default function NotFound() {
    return <NotFoundContent/>;
}