export const LOAD_USERS_START = 'LOAD_USERS_START';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'LOAD_USERS_END';

export const SEARCH_USERS_START = 'SEARCH_USERS_START';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_ERROR = 'SEARCH_USERS_END';

export const FILTER_USERS_START = 'FILTER_USERS_START';
export const FILTER_USERS_SUCCESS = 'FILTER_USERS_SUCCESS';
export const FILTER_USERS_ERROR = 'FILTER_USERS_END';

export const SORT_USERS_START = 'SORT_USERS_START';
export const SORT_USERS_SUCCESS = 'SORT_USERS_SUCCESS';
export const SORT_USERS_ERROR = 'SORT_USERS_END';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_END';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_END';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_END';
