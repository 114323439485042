import * as types from './uploadActionTypes';

export const uploadFileStart = (file: File) => ({
    type: types.UPLOAD_FILE_START,
    payload: file,
});


export const uploadFileSuccess = () => ({
    type: types.UPLOAD_FILE_SUCCESS,
});

export const uploadFileError = (error: any) => ({
    type: types.UPLOAD_FILE_ERROR,
    payload: error,
    error: true,
});

export const uploadFileCancel = () => ({
    type: types.UPLOAD_FILE_CANCEL,
    payload: "canceled",
    error: true,
});

export const uploadFileProgress = (progress: number) => ({
    type: types.UPLOAD_FILE_PROGRESS,
    payload: progress,
});