import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import _rootReducer from "../reducers/_rootReducer";
import rootSaga from "../reducers/_rootSagas";

const preloadedState = {};

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const store = configureStore({
  reducer: _rootReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
            serializableCheck: false
      }).concat(middleware),
  devTools: process.env.NODE_ENV !== 'development',
  preloadedState,
});

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;