import React from "react";
import { Navigate } from "react-router-dom";

export type ProtectedRouteProps = {
    isLoggedIn: boolean;
    loginPath: string;
    outlet: JSX.Element;
};

export default function ProtectedRoute({isLoggedIn,  loginPath,  outlet}: ProtectedRouteProps) {
    if(isLoggedIn) {
        return outlet;
    } else {
        return <Navigate to={{ pathname: loginPath }} />;
    }
};