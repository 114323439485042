interface QueryFromProps {
    props: any;
}

const getQueryFromPropsQuick = ({props}: QueryFromProps) => {
    const mappedProps: {[index: string]: string} = {};
    console.log('props', props)
    props.filterModel.quickFilterValues.forEach((element: string, index: number) => {
        console.log('element', element)
        mappedProps[`quick${index}`] = element;
    });
    return `?${new URLSearchParams(mappedProps).toString()}`;
};
export default getQueryFromPropsQuick;
