import React, {useState} from 'react';
import {useAppSelector, useAppDispatch} from '../app/hooks';
import {toggleTheme, selectThemeSwitcher} from '../reducers/utils/settingsReducer';
import {
    IconButton, Tooltip,
} from "@mui/material";
import {
    DarkModeOutlined,
    LightModeOutlined,
} from "@mui/icons-material";
import {Box} from "@mui/system";

export function ThemeSwitcher() {
    const theme = useAppSelector(selectThemeSwitcher);
    const dispatch = useAppDispatch();

    return (
        <Box
            sx={{

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                p: '8px',
            }}
        >
            <Tooltip title="color mode" arrow placement="right-start">
            <IconButton onClick={() => dispatch(toggleTheme())}
            >
                {theme ? (
                    <DarkModeOutlined sx={{fontSize: "25px"}}/>
                ) : (
                    <LightModeOutlined sx={{fontSize: "25px", color: 'white'}}/>
                )}
            </IconButton>
            </Tooltip>
        </Box>
    );
}
