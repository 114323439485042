import { createSlice } from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

const initialState = {
    message: ''
};

const messageReducer = createSlice({
    name: "message",
    initialState,
    reducers: {
        setMessage: (state, action) => {
            return { message: action.payload };
        },
        clearMessage: () => {
            return { message: "" };
        },
    },
});
export const selectMessage = (state: RootState) => state.message.message;

export const { setMessage, clearMessage } = messageReducer.actions

export default messageReducer.reducer;